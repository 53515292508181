import React, { useEffect } from "react";
import { Layout, Modal, theme } from "antd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AnimationBG from "../pages/Home/Partials/AnimationBG";
import PayModal from "../pages/Home/Partials/PayModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import Settings from "./partials/Settings";
import UserMenu from "./partials/UserMenu";
import LogoSrc from "../../assets/images/Abby_3x.png";
import smLogoSrc from "../../assets/images/Abby_3x.png";
import constants from "../../config/constants";
import { setDarkMode, setOpenPayModal } from "../../redux/app/appSlice";
import { getPlans } from "../../redux/plan/planSlice";

const { useToken } = theme;
const { Header } = Layout;

const stripePromise = loadStripe(constants.stripePK);

function AuthLayout({ children }) {
  const { token } = useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isDarkMode = useSelector((state) => state.app.isDarkMode);
  const openPayModal = useSelector((state) => state.app.openPayModal);
  const user = useSelector((state) => state.auth.user);
  const plans = useSelector((state) => state.plan.plans ?? []);

  useEffect(() => {
    dispatch(getPlans());
    if (plans.length === 0) {
    }
  }, []);

  const changeTheme = () => {
    dispatch(setDarkMode());
  };

  const setOpenModal = () => {
    dispatch(setOpenPayModal());
  };

  return (
    <Layout className={classNames("min-h-screen", location.pathname == '/voice-playground' && "bg-[#0c111b]")}>
      <Header
        className={classNames(`shadow-lg sticky px-0 top-0 z-[999]`, location.pathname == '/voice-playground' ? "bg-transparent" : "bg-primary")}
      >
        <div className="flex items-center justify-between px-2 mx-auto max-w-7xl">
          <div
            className={classNames(
              "demo-logo h-[64px]"
              // !isDarkMode && "bg-white"
            )}
          >
            <Link to="/playground" className="hidden sm:inline">
              <img
                src={LogoSrc}
                alt="logo"
                className={classNames(
                  "w-[96px] p-3 text-white"
                  // !isDarkMode && "invert"
                )}
              />
            </Link>
            <Link to="/playground" className="inline sm:hidden">
              <img
                src={smLogoSrc}
                alt="logo"
                className={classNames(
                  "w-[96px] p-3 text-white"
                  // !isDarkMode && "invert"
                )}
              />
            </Link>
          </div>
          <div className="flex items-center">
            <div>
              <Link to="/playground" className="mr-4 text-white sm:mr-6">
                Text Chat
              </Link>
              <Link to="/voice-playground" className="mr-4 text-white sm:mr-6">
                Voice Chat
              </Link>
            </div>
            {/* <div className="mr-2 sm:mr-6">
                <Button type="primary" icon={<FileAddOutlined />} onClick={() => {
                  // return navigate('/home');
                  if (user.activeSubscription && user.activeSubscription.status == 'active') {
                    return navigate('/home');
                  }
                  if (user.activeSubscription && user.activeSubscription.status != 'active') {
                    Modal.confirm({
                      title: 'Your subscription past due.',
                      icon: <ExclamationCircleFilled />,
                      content: 'Will you continue it?',
                      okText: "Yes",
                      cancelText: "No",
                      onOk() {
                        window.open(user.activeSubscription.hosted_invoice, '_blank');
                        return new Promise((resolve, reject) => {
                          setTimeout(resolve, 1000);
                        }).catch(() => console.log('Oops errors!'));
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    setOpenModal();
                  }
                }}>New Project</Button>
              </div> */}
            <UserMenu />
          </div>
        </div>
      </Header>
      {/* <div onClick={() => {
          navigate(-1);
        }} className={classNames("sticky w-[75px] cursor-pointer top-20 -ml-11 hover:ml-0 ease-in-out transition-[margin] z-[998] px-2.5 py-2 rounded-e-3xl", isDarkMode ? "bg-gray-100" : "bg-gray-300")}>
          <span>Back</span> &nbsp; <ArrowLeftOutlined />
        </div> */}
      <Layout /* className="h-[1000px]" */ className="bg-primary">
        {/* {location.pathname == '/voice-playground' && <AnimationBG />} */}
        {children}
      </Layout>
      {/* <ScrollToTop smooth className="animate-bounce" /> */}
      {/* <FloatButton.BackTop /> */}
      {/* <Settings /> */}
      <Elements stripe={stripePromise} nonce="random-nonce">
        <PayModal
          open={openPayModal}
          setOpen={setOpenModal}
          price={plans[0]?.price}
          planId={plans[0]?._id}
          setSuccessful={() => { }}
        />
      </Elements>
    </Layout>
  );
}

export default AuthLayout;
